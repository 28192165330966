
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContactView',
  components: {},
  data() {
    return {
      common: [
        {
          'tittle': 'preformulation',
          'p': 'Raw material research: study of physical, chemical, and biological properties, mass spectrometry of raw materials, compatibility testing of raw materials and excipients: select commonly used excipients for ordinary tablets for compatibility testing of raw materials and excipients'
        },
        {
          'tittle': 'Research on Prescription Process',
          'p': 'Research on powder mixing process and dry granulation method: raw materials and processing, mixing method and time, particle size distribution, flowability, moisture absorption curve investigation, tablet pressing, coating research'
        },
        {
          'tittle': 'Development and validation of formulation analysis methods',
          'p': 'Development of analytical methods for formulations, drafting of quality standards: analytical methods for substance analysis, dissolution testing, microbiological testing, and cleaning confirmation'
        },
        {
          'tittle': 'Preparation of application batch sample test',
          'p': 'IND application batch sample test (1 batch for each specification, with a tentative batch size of 5000 pieces), Party B shall conduct the application batch sample test (Party B shall be responsible for the procurement, inspection and release of auxiliary materials and packaging materials)'
        },
        {
          'tittle': 'Clinical Batch (GMP)',
          'p': 'GMP batch (1 batch per specification, with a tentative batch size of 10000 pieces)'
        },
        {
          'tittle': 'Research on the Quality of Preparations',
          'p': 'Two batches of enlarged production samples undergo full inspection'
        },
        {
          'tittle': 'Sample stability test',
          'p': 'Sample characteristics, dissolution rate, related substances, moisture content, microbial limit, crystal form test, with one IND application batch and one clinical batch for each specification'
        },
        {
          'tittle': 'Declaration materials',
          'p': 'Data 3.2. P.1-3.2. P.8 and 2.3. P (NMPA)'
        },
      ]
    }
  }
})
