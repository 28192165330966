
import {defineComponent} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore()
    const router  = useRouter()
    const route = useRoute()
    
    const change = (lang: string) => {
      store.commit('changeLang', lang)

      switch (route.path) {
        case '/':
        case '/home_yingwen':
          if (lang == 'zh') {
            router.replace('/')
          } else {
            router.replace('/home_yingwen')
          }
          break
        case '/news':
        case '/news_yingwen':
          if (lang == 'zh') {
            router.replace({
              path: '/news',
              query: route.query
            })
          } else {
            router.replace({
              path: '/news_yingwen',
              query: route.query
            })
          }
          break
        case '/res':
        case '/res_yingwen':
          if (lang == 'zh') {
            router.replace('/res')
          } else {
            router.replace('/res_yingwen')
          }
          break
        case '/contact':
        case '/contact_yingwen':
          if (lang == 'zh') {
            router.replace('/contact')
          } else {
            router.replace('/contact_yingwen')
          }
          break
        case '/zhaobiao':
        case '/zhaobiao_yingwen':
          if (lang == 'zh') {
            router.replace('/zhaobiao')
          } else {
            router.replace('/zhaobiao_yingwen')
          }
          break
        case '/zhaobiao2':
        case '/zhaobiao2_yingwen':
          if (lang == 'zh') {
            router.replace('/zhaobiao2')
          } else {
            router.replace('/zhaobiao2_yingwen')
          }
          break
        default:
          break
      }
    }

    const link = (url: string) => {
      router.push(url)
    }

    const jump = (index: number) => {

      router.push({
        path: store.state.lang === 'zh' ? `/news` : '/news_yingwen',
          query: {
            index: index
          }
        }
      )
    }

    return {
      change,
      link,
      jump
    }
  }
})
