
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NewsView',
  components: {},
  data() {
    return {
      index: this.$route.query.index,
      news: [
        {
          tittle: 'Acetylpropyl cysteine capsules have been accepted for clinical trial application by the China National Medical Products Administration',
          img: [
            require('/src/assets/news/accept.png'),
            require('/src/assets/news/0-2.png')
          ],
          p: 'The application for clinical trials of propargyl cysteine (SPRC) capsules has been accepted by the China National Medical Products Administration. Indications grouping: Rheumatic diseases and immune drugs, with the first 50mg specification and the second 100mg specification, respectively.\n' +
              'Application: Registration of clinical trials for domestically produced drugs\n' +
              'Angiogenesis Product Name: Acetylpropyl Cysteine Capsules\n' +
              'Number:CXHL2300748\n' +
              'H.S Number:CXHL2300749 \n' +
              'Acceptance date:2023年7月7日。'
        },
        {
          tittle: 'Yinuo Technology founder Yi Zhun Zhu elected as a member of the European Academy of Sciences and Arts',
          img: [
            require('/src/assets/news/C_ZhuYiZhun.jpg')
          ],
          p: 'Yinuo Technology founder Yi Zhun Zhu elected as a member of the European Academy of Sciences and Arts.\n' +
              ' \n' +
              '\n' +
              'It is reported that the European Academy of Sciences and Arts is a prestigious academic organization in the fields of science and art worldwide, with over 2000 academicians to date, including 37 Nobel laureates, making it one of the academies with the highest proportion of Nobel laureates. The European Academy of Sciences and Arts currently has seven faculties, including Humanities, Medicine, Arts, Natural Sciences, Social Sciences, Law and Economics, and Technology and Environmental Sciences. So far, about 20 academicians and outstanding scholars of the Chinese Academy of Sciences and the Chinese Academy of Engineering have been elected as academicians of the European Academy of Sciences and Arts.\n' +
              ' \n' +
              '\n' +
              'Yi Zhun Zhu has been awarded honors such as the National Science Fund for Distinguished Young Scholars, the Changjiang Scholar Distinguished Professor of the Ministry of Education, the Chief Scientist of the National Major Research Program (973), and the Chief Scientist of the National Major New Drug Creation Platform. She is also a member of the National Pharmacopoeia Committee, the Innovation Committee of the Overseas Chinese Affairs Office of the State Council, a recipient of the Shanghai Magnolia Honor Award, and the chief editor of the National Health Commission "Clinical Medication in China" (first edition), "Pharmacology" National Planning Textbook (seventh and eighth editions), and English version (first edition). He is the Honorary President of the Macau Chinese Medical Association and also serves as the Chief Physician of the Department of Cardiology at the First Affiliated Hospital of the University of Macau. Yi Zhun Zhu has published over 330 scientific papers, with more than 16500 citations (h-index 69, i10 index 245), and has been ranked among the top 100000 scientists in the world and the top 2% of global scientists by Stanford University. The two first in class new drugs he developed have entered Phase I and Phase II clinical trials in China and the United States, respectively.\n',
          source: 'source https://mp.weixin.qq.com/s/vzo56Ic0li9CYnPrEYujWA'
        },
        {
          tittle: '「第十一屆亞洲藥學院校聯合會會議」順利舉辦',
          img: [
            require('/src/assets/news/AASP.jpg'),
            require('/src/assets/news/2-2.jpg'),
            require('/src/assets/news/2-3.jpg')
          ],
          p: '2023年8月4日至5日，由澳門科技大學主辦的「第十一屆亞洲藥學院校聯合會會議」在澳門科技大學順利舉行。會上，依諾科技創始人、澳科大協理副校長、醫學院（藥學院）院長朱依諄院士當選亞洲藥學院校聯合會（AASP）主席。\n' +
              '\n' +
              '亞洲藥學院校聯合會（Asian Association of Schools of Pharmacy，簡稱AASP）成立於2001年4月，由新加坡國立大學、香港中文大學和台灣大學等聯合創立。成員為來自亞洲的日本、韓國、新加坡、印度、印尼、菲律賓、馬來西亞、泰國、越南、伊朗、紐西蘭、澳大利亞以及中國的各大學的藥學院、系和獨立的藥科大學，亞洲藥學院校聯合會是一個非營利的民間專業組織，致力於服務各個成員單位，以促進亞太區域藥學教育和藥學研究的發展。是次會議為來自亞洲不同藥學院的學術界和學生之間的互動和交流提供寶貴的機會。\n' +
              '\n' +
              '在會上，朱依諄院士獲當選爲下一屆AASP主席，其表示將進一步促進亞洲及大洋洲高等教育團體藥學教育工作者之間有更多交流。AASP也會通過提高資源共用，以及在教學、科研和服務方面，為教師和學生提供更多機會，推動亞太地區藥學共同進步和發展，促進亞太與世界各藥學團體之間的交流與合作，為全人類的健康做出更多貢獻。\n' +
              ' \n' +
              '在這次精彩紛呈、充滿啟發的學術競賽上，參賽者共同經歷了知識的碰撞、思想的交流，不僅深化了學術領域的探討，更增進了相互之間的了解與合作。圍繞著諸多重要議題展開了深入的研討，分享了創新的理念與前沿的研究成果，使得這個學術盛宴成為了青年學者共同成長與進步的契機。參賽者展望未來，將繼續保持學術交流的活躍，秉持開放、合作、創新的精神，共同推動藥學學科的發展；攜手前行，迎接新的挑戰。與會者在會後參觀了校園及大學國家重點實驗室對澳門科技大學的飛速發展和科學成就表示出極大地佩服。\n',
          source: '本文摘自 https://mp.weixin.qq.com/s/vzo56Ic0li9CYnPrEYujWA'
        },
        {
          tittle: 'Innodrug original discovery and clinical research of inflammatory and immune drugs won the 13th China Innovation and Entrepreneurship Competition (Guangdong Division)\n'+
          'The first prize of the start-up team of the 12th "the Pearl River Angel Cup" Science and Technology Innovation and Entrepreneurship Competition',
          img: [
            require('/src/assets/news/4-1.jpg'),
            require('/src/assets/news/4-2.jpg'),
            require('/src/assets/news/4-3.jpg')
          ],
          p: '\n' +
              ' \n' +
              '\n' +
              'On October 25, 2024, in order to implement the innovation driven development strategy, strengthen the dominant position of scientific and technological innovation of enterprises, promote the deep integration of scientific and technological innovation and industrial innovation, and develop new quality productivity, the 13th China Innovation and Entrepreneurship Competition (Guangdong Area) and the 12th "the Pearl River Angel Cup" Science and Technology Innovation and Entrepreneurship Competition Finals and Award Ceremony, sponsored by the Department of Science and Technology of Guangdong Province, hosted by the Productivity Promotion Center of Guangdong Province and the Guangdong Science and Technology Financial Promotion Association, and co organized by local and municipal science and technology bureaus, were held in the Guangzhou campus of the Hong Kong University of Science and Technology in Nansha, Guangzhou.\n' +
              ' \n' +
              '\n' +
              'The China Innovation and Entrepreneurship Competition is the largest, most widely radiated, and most influential innovation and entrepreneurship competition in the country. The theme of this conference is "Gathering through Innovation, Moving Forward with New Colleagues". The competition went through preliminary, semi-final, and final rounds, with a total of 2000 companies registering for the preliminary round and 100 companies entering the final round; The selection process is based on the unified entry conditions, evaluation rules, and evaluation criteria formulated by the Office of the Organizing Committee of the China Innovation and Entrepreneurship Competition. Strict qualification review is carried out for participating companies, and a strict project selection process and an open and transparent selection mechanism are established. Following the principles of "openness, fairness, impartiality, and competitive selection", the selection of participating projects is carried out around "technology and product innovation", "market prospects and competition", "management team", "finance and financing", etc. The most promising projects and enterprises with market potential and investment value are selected, and the judges independently score and publicly announce the scores on site.\n'+
              ' \n' +
              '\n' +
              'After fierce competition, Academician Yi Zhun Zhu "Discovery and Clinical Research of Original Inflammatory and Immune Drugs" project surpassed entrepreneurial teams such as the University of Hong Kong, Peking University, Chinese Academy of Sciences, Shanghai Jiao Tong University, and Harbin Institute of Technology, and won the first prize in the Guangdong region start-up group with the highest overall score.\n'+
              '\n'+
              '\n'+
              'And it is scheduled to participate in the 13th China Innovation and Entrepreneurship Competition Biomedical National Competition held in Taizhou City, Jiangsu Province from November 18 to 21, 2024】。'
        },
      ]
    }
  }
})
