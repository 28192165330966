
import { defineComponent } from 'vue'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
export default defineComponent({
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      news: [
        {
          'img': require('/src/assets/news/accept.png'),
          'text': 'Acetylpropyl cysteine capsules have been accepted for clinical trial application by the China National Medical Products Administration',
          'link': ''
        },
        {
          'img': require('/src/assets/news/C_ZhuYiZhun.jpg'),
          'text': 'Innodrug founder Zhu Yizhen elected as a member of the European Academy of Sciences and Arts',
          'link': ''
        },
      ],
      newProduct: [
        {
          img: require('/src/assets/medicine/SCM198_1.png'),
          tittle: 'SCM-198',
          p: 'Medical research on the conversion of small molecule active substances from traditional Chinese medicine sources\n' +
              'The medicinal crystal form of Leonurine has obtained clinical trial approval for Class I original chemical drugs'
        },
        {
          img: require('/src/assets/medicine/ZYZ802_1.png'),
          tittle: 'ZYZ-802',
          p: 'ZYZ-802 has anti-inflammatory effects and can significantly resist rheumatoid diseases caused by inflammation\n' +
              'can significantly inhibit the development of rheumatoid arthritis and improve prognosis'
        },
      ]
    }
  },
  methods: {
    jump(index: number){
      this.$router.push({
            path: `/news`,
            query: {
              index: index
            }
          }
          // `/news/${index}`
      )
    }
  }
});
