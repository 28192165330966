import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from "@/views/NewsView.vue";
import ResView from "@/views/ResView.vue";
import ContactView from "@/views/ContactView.vue";
import ZhaobiaoView from "@/views/ZhaobiaoView.vue";
import Zhaobiao2View from "@/views/Zhaobiao2View.vue";
import Zhaobiao3View from "@/views/Zhaobiao3View.vue";
import HomeView_yingwen from '@/views/HomeView_yingwen.vue'
import NewsView_yingwen from "@/views/NewsView_yingwen.vue";
import ResView_yingwen from "@/views/ResView_yingwen.vue";
import ContactView_yingwen from "@/views/ContactView_yingwen.vue";
import ZhaobiaoView_yingwen from "@/views/ZhaobiaoView_yingwen.vue";
import Zhaobiao2View_yingwen from "@/views/Zhaobiao2View_yingwen.vue";
import Zhaobiao3View_yingwen from "@/views/Zhaobiao3View_yingwen.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/res',
    name: 'res',
    component: ResView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/zhaobiao',
    name: 'zhaobiao',
    component: ZhaobiaoView
  },
  {
    path: '/zhaobiao2',
    name: 'zhaobiao2',
    component: Zhaobiao2View
  },
  {
    path: '/zhaobiao3',
    name: 'zhaobiao3',
    component: Zhaobiao3View
  },
  {
    path: '/home_yingwen',
    name: 'home_yingwen',
    component: HomeView_yingwen
  },
  {
    path: '/news_yingwen',
    name: 'news_yingwen',
    component: NewsView_yingwen
  },
  {
    path: '/res_yingwen',
    name: 'res_yingwen',
    component: ResView_yingwen
  },
  {
    path: '/contact_yingwen',
    name: 'contact_yingwen',
    component: ContactView_yingwen
  },
  {
    path: '/zhaobiao_yingwen',
    name: 'zhaobiao_yingwen',
    component: ZhaobiaoView_yingwen
  },
  {
    path: '/zhaobiao2_yingwen',
    name: 'zhaobiao2_yingwen',
    component: Zhaobiao2View_yingwen
  },
  {
    path: '/zhaobiao3_yingwen',
    name: 'zhaobiao3_yingwen',
    component: Zhaobiao3View_yingwen
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

 

export default router
